import React from 'react'
import './Footer.css'
import Logo from '../../Images/tmvLogo2-r.png'
import { NavLink } from 'react-router-dom'

function Footer() {
  return (
    <footer className='footer'>
      <div className="footer-container1">
        <div className="footer-container1-box1">
          <i class="fa-solid fa-location-dot"></i>
          <div className="address">
            <h3>Find Us</h3>
            <p>TMV - Kharghar Plot No - 3, Sector - 14, Near Raghunath-Vihar CHS,Kharghar -410210</p>
          </div>
        </div>
        <div className="footer-container1-box2">
          <i class="fa-solid fa-phone"></i>
          <div className="address">
            <h3>Call Us</h3>
            <p>+91 98921 10892</p>
          </div>
        </div>
        <div className="footer-container1-box3">
          <i class="fa-solid fa-envelope"></i>
          <div className="address">
            <h3>Mail Us</h3>
            <p>info@tmvkharghar.in</p>
          </div>
        </div>
      </div>
      <div className="footer-container2">
        <div className="footer-container2-box1">
          <img src={Logo} alt="" />
          <h3>Follow Us</h3>
          <div className="social-icons">
            <i class="fa-brands fa-facebook"></i>
            <i class="fa-brands fa-instagram"></i>
            <i class="fa-brands fa-twitter"></i>
          </div>
        </div>
        <div className="footer-container2-box2">
          <h3>Quick Links</h3>

          <div className="links">
            <ul >
            <li> <NavLink to="/">Home</NavLink> </li>
              <li> <NavLink to="/about">About Us</NavLink> </li>
              <li> <NavLink to="/faculty">Faculty</NavLink> </li>
              <li> <NavLink to="/departments">Departments</NavLink> </li>
              <li> <NavLink to="/Facilities">Facilities</NavLink> </li>
              <li> <NavLink to="/students-corner">Students Corner</NavLink> </li>
            </ul>
          </div>
        </div>
        <div className="footer-container2-box2">
          <h3>Quick Links</h3>

          <div className="links">
            <ul >
              <li> <NavLink to="/students-corner">Students Corner</NavLink> </li>
              <li> <NavLink to="/admission-center">Admission Center</NavLink> </li>
              <li> <NavLink to="/news-development">News & Development</NavLink> </li>
              <li> <NavLink to="/tmv-gallery">TMV Gallery</NavLink> </li>
              <li> <NavLink to="/contact-us">Contact Us</NavLink> </li>
            </ul>
          </div>
        </div>
        <div className="footer-container2-box3">
          <h3>Reach Us</h3>
          <div className='mapBox'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15085.754989007022!2d73.0740042!3d19.0444378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c210444a52e3%3A0x353c5655e695052d!2z4KSf4KS_4KSz4KSVIOCkruCkueCkvuCksOCkvuCkt-CljeCkn-CljeCksCDgpLXgpL_gpKbgpY3gpK_gpL7gpKrgpYDgpKAsIOCkluCkvuCksOCkmOCksA!5e0!3m2!1smr!2sin!4v1715579458289!5m2!1smr!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='tmv'></iframe>
          </div>
        </div>
      </div>
      <div className="footer-container3">
        <div className="footer-container3-box1">
          <h3>&copy;2024 TMV Kharghar | All Rights Reserved
          </h3>
        </div>
        <div className="footer-container3-box2">
          <h3>Designed By Shekhar Pandge</h3>
        </div>
      </div>
    </footer>
  )
}

export default Footer
