import React from 'react'
import tmvUnderline from '../Images/tmvUnderline.png'
import tmvBlackUnderline from '../Images/blackUnderline.png'
import profile from '../Images/fac.jpg'
import second from '../Images/tmv2.jpg'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

function SingleFacultyPage(props) {
  return (
    <>
    <Header/>
    <div class="pageHeader">
        <h3>Faculty</h3>
        <img src={tmvUnderline} alt="" />
    </div>
    <div className="newsContainer">
        <div className="newsImg">
          <img src={profile} alt="" />
        </div>
        <div className="info">
          <h3>Computer Scienece Dept.</h3>
          <h1>{props.name}</h1>
          <img src={tmvBlackUnderline} alt="" />
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, voluptatibus?Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem a iusto laborum impedit odio vero culpa mollitia veritatis voluptate quae?Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi, iure?
          </p>
          <div className="points">
            <div className="first">
              <img src={second} alt="" />
              <div>
                <h1>Lorem ipsum dolor sit</h1>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                </p>
              </div>
            </div>
            <div className="first">
              <img src={second} alt="" />
              <div>
                <h1>Lorem ipsum dolor sit</h1>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default SingleFacultyPage
