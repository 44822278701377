import React from 'react'
import './About.css'
import tmvUnderline from '../../Images/tmvUnderline.png'
import aboutBg from '../../Images/tmvBg2024-r.jpg'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

function About() {
  return (
    <>
    <Header/>
      <div class="pageHeader">
        <h3>About Us</h3>
        <img src={tmvUnderline} alt="" />
      </div>
      <div className="aboutUsContainer">
        <div className="aboutUsContainer1">
          <h1>Our Story</h1>
        </div>
        <div className="aboutUsContainer2">
          <p>The origin of the Vidyapeeth dates back to the pre-independence period - 1921 when immediately after the demise of Lokmanya Bal Gangadhar Tilak, his follower Mahatma Gandhi mooted the idea of establishing a university in his memory. As we all are aware one of the postulates of the four fold formula conceived by Lokmanya Tilak was National Education, the other three being Swaraj, Swadeshi and Boycott of Foreign Goods. The delegates of the first Maharashtra Provincial Conference on 6th May 1921 under the Presidentship of Shrimat Shankaracharya of Karveer Peeth decided to have a National University in Maharashtra and thus the present day Tilak Maharashtra Vidyapeeth (TMV) was established.</p>
        </div>
      </div>
      <div className="aboutUsInfo">
        <div className="aboutUsInfo1">
          <p>Since it's inception, TMV, the public funded university had world renowned scholar Shri Shankaracharya Vidyashankar Bharati alias Dr. Kurtakoti as its first Chancellor. This post has been held by other eminent personalities of great intellect and recognition at the national level such as the great Sanskrit Scholar Bharatacharya Shri. C. V. Vaidya, the first premier of Bombay Presidency Shri. B. G. Kher, Dr. M. S. alias Lokanayak Bapusaheb Aney, a great Sanskrit scholar and a historian Mahamahopadhyay Datto Vaman Potdar, and Honorable Shri. Y. B. Chavan, Shri. S. B. Chavan, Shri. Shivraj Patil, Shri. Sushilkumar Shinde - all Union Home Ministers. During the pre-independence period, degrees awarded in the faculties of Arts, Management, Commerce and Engineering as well as the research works in Sanskrit and Ayurveda were world acclaimed and recognized.
          </p>
        </div>
        <div className="aboutUsInfo2">
          <img src={aboutBg} alt="" />
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default About
