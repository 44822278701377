import React from 'react'
import tmvUnderline from '../../Images/tmvUnderline.png'
import './Departments.css'
import Logo from '../../Images/tmvLogo2.png'
import CS from '../../Images/compLab.jpg'
import PH from '../../Images/chemLab.jpg'
import LAW from '../../Images/library.jpg'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Link } from 'react-router-dom'


function Departments() {
  return (
    <>
      <Header/>
      
      <div class="pageHeader">
        <h3>Departments</h3>
        <img src={tmvUnderline} alt="" />
      </div>
      <div className="departmentsContainer">
        <div className="departmentsContainer1">
          <img src={Logo} alt="" />
          <h2>Computer Science</h2>
          <p>Cutting-edge programming, AI, and cybersecurity courses for tech enthusiasts.</p>
          <p>HOD : Mrs. Poornima Jangle</p>
          <Link to="/comp-dept"><button>Know More</button></Link>
        </div>
        <div className="departmentsContainer2">
          <img src={CS} alt="" />
        </div>
        <div className="departmentsContainer3">
          <h2>Courses</h2>
          <div className="skillIcon">
            <i class="fa-solid fa-check-to-slot"></i>
            <h3>BCA</h3>
          </div>
          <div className="skillIcon">
            <i class="fa-solid fa-check-to-slot"></i>
            <h3>BCA - CS</h3>
          </div>
          <div className="skillIcon">
            <i class="fa-solid fa-check-to-slot"></i>
            <h3>MCA</h3>
          </div>
          <div className="skillIcon">
            <i class="fa-solid fa-check-to-slot"></i>
            <h3>MSC</h3>
          </div>
        </div>
      </div>
      <div className="departmentsContainer">
        <div className="departmentsContainer1">
          <img src={Logo} alt="" />
          <h2>Pharmacy</h2>
          <p>Pharmacology, drug development, and healthcare management education for aspiring pharmacists.</p>
          <p>HOD : Name Of HOD</p>
          <Link to="/pharmacy-dept"><button>Know More</button></Link>
        </div>
        <div className="departmentsContainer2">
          <img src={PH} alt="" />
        </div>
        <div className="departmentsContainer3">
          <h2>Courses</h2>
          <div className="skillIcon">
            <i class="fa-solid fa-check-to-slot"></i>
            <h3>B Pharmacy</h3>
          </div>
          <div className="skillIcon">
            <i class="fa-solid fa-check-to-slot"></i>
            <h3>M Pharmacy</h3>
          </div>
          
        </div>
      </div>
      <div className="departmentsContainer pb">
        <div className="departmentsContainer1">
          <img src={Logo} alt="" />
          <h2>Law Department</h2>
          <p>Jurisprudence, constitutional law, litigation, and legal research methodologies emphasized.</p>
          <p>HOD : Name Of HOD</p>
          <Link to="/law-dept"><button>Know More</button></Link>
        </div>
        <div className="departmentsContainer2">
          <img src={LAW} alt="" />
        </div>
        <div className="departmentsContainer3">
          <h2>Courses</h2>
          <div className="skillIcon">
            <i class="fa-solid fa-check-to-slot"></i>
            <h3>BA LLB</h3>
          </div>
          <div className="skillIcon">
            <i class="fa-solid fa-check-to-slot"></i>
            <h3>LLB</h3>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Departments
