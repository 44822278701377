import React from 'react';
import './MarqueeNotification.css';

const MarqueeNotification = () => {
    return (
        <div className="marquee-container">
            <div className="marquee-content">
                <a href="https://www.tmv.edu.in/frmExamCorner1.aspx?val=All" className="marquee-link" target="_blank" rel="noopener noreferrer">
                    TMV 2024 - 25 Academic Year Exam Notification
                </a>
                <a href="https://www.tmv.edu.in/frmExamCorner1.aspx?val=All" className="marquee-link" target="_blank" rel="noopener noreferrer">
                    TMV 2024 - 25 Academic Year Exam Notification
                </a>
            </div>
        </div>
    );
};

export default MarqueeNotification;
