import React from 'react'
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from './pages/Home/Homepage';
import AboutUsPage from './pages/About/AboutUsPage';
import Faculty from './pages/Faculty/FacultyPage';
import Departments from './pages/Departments/Departments';
import Facilities from './pages/Facilities/Facilities';
import StudentsCorner from './pages/StudentCorner/StudentsCorner';
import AdmissionCenter from './pages/Admission/AdmissionCenter';
import NewsAndDevelopments from './pages/NewsAndDevelopment/NewsAndDevelopment';
import TMVGallery from './pages/TMVGallery/TMVGallery';
import ContactUs from './pages/Contact/ContactUs';
import ComputerScienceDept from './components/ComputerScienceDept';
import PharmacyDept from './components/PharmacyDept';
import LawDepartment from './components/LawDepartment';
import SingleFacultyPage from './components/SingleFacultyPage';
import ScrollTop from './components/ScrollToTop/Scroll'


const App = () => {
  return (
    <Router>
      <ToastContainer />
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/faculty" element={<Faculty />} />
        <Route path="/departments" element={<Departments />} />
        <Route path="/facilities" element={<Facilities />} />
        <Route path="/students-corner" element={<StudentsCorner />} />
        <Route path="/admission-center" element={<AdmissionCenter />} />
        <Route path="/news-and-developments" element={<NewsAndDevelopments />} />
        <Route path="/tmv-gallery" element={<TMVGallery />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/comp-dept" element={<ComputerScienceDept />} />
        <Route path="/pharmacy-dept" element={<PharmacyDept />} />
        <Route path="/law-dept" element={<LawDepartment />} />
        <Route path="/single-faculty-page-1" element={<SingleFacultyPage name="Poornima Jangle" />} />
        <Route path="/single-faculty-page-2" element={<SingleFacultyPage name="Deepmala Sharma" />} />
        <Route path="/single-faculty-page-3" element={<SingleFacultyPage name="Minal Ma'am" />} />
      </Routes>

    </Router>
  )
}

export default App