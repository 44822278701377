import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Logo from '../../Images/tmvLogo2-r.png';
import './Header.css'
import MarqueeNotification from '../MarqueeNotification/MarqueeNotification';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className={isMenuOpen ? "mobileMenu open" : "close"}>
        <li> <NavLink to="/" onClick={toggleMenu}>Home</NavLink> </li>
        <li> <NavLink to="/about-us" onClick={toggleMenu}>About Us</NavLink> </li>
        <li> <NavLink to="/faculty" onClick={toggleMenu}>Faculty</NavLink> </li>
        <li> <NavLink to="/departments" onClick={toggleMenu}>Departments</NavLink> </li>
        <li> <NavLink to="/Facilities" onClick={toggleMenu}>Facilities</NavLink> </li>
        <li> <NavLink to="/students-corner" onClick={toggleMenu}>Students Corner</NavLink> </li>
        <li> <NavLink to="/admission-center" onClick={toggleMenu}>Admission Center</NavLink> </li>
        <li> <NavLink to="/news-and-developments" onClick={toggleMenu}>News & Development</NavLink> </li>
        <li> <NavLink to="/tmv-gallery" onClick={toggleMenu}>TMV Gallery</NavLink> </li>
        <li> <NavLink to="/contact-us" onClick={toggleMenu}>Contact Us</NavLink> </li>
      </div>
      <div className="option">
        <div className="iconsTopbar">
          <i class="fa-brands fa-instagram"></i>
          <i class="fa-brands fa-facebook"></i>
          <i class="fa-brands fa-youtube"></i>
          <i class="fa-solid fa-location-dot"></i>
        </div>
        <MarqueeNotification />
        <div className="optionsButtons">
          {/* <Link to="/"><button className='btn1'> View Exam Notifcation</button></Link> */}
          <Link to="https://tmv.edu.in" target='blank'><button className='btn1'>tmv.edu.in</button></Link>
        </div>

      </div>
      <header className="header">
        <div className="logo">
          <img src={Logo} alt="" />
        </div>
        <div className="navbar">
          <div className="mobileButtons">
            <Link to="https://tmv.edu.in" target='blank'><button className='btn1'>tmv.edu.in</button></Link>
          </div>
          <div className="hamburger" onClick={toggleMenu}>
            <i className={isMenuOpen ? "fa-solid fa-times" : "fa-solid fa-bars"}></i>
          </div>

          <div className="lowerNavbar">
            <ul>
              <li className='phonediv'>
                <div className='link-style'>
                  <i class="fa-solid fa-phone"></i>
                  <p>+91 98921 10892</p>
                </div>
              </li>
              <li className='maildiv'>
                <div className='link-style'>
                  <i class="fa-solid fa-envelope"></i>
                  <p>info@tmvkharghar.in</p>
                </div>
              </li>
              <li className='addressdiv'>
                <div className='link-style'>
                  <i class="fa-solid fa-location-dot"></i>
                  <p>Tilak Maharashtra Vidyapeeth - Kharghar Plot No - 3, Sector - 14, Near Raghunath-Vihar CHS,Kharghar -410210</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="upperNavbar">
            <ul className='mainnav'>
              <li> <NavLink to="/">Home</NavLink> </li>
              <li> <NavLink to="/about-us">About Us</NavLink> </li>
              <li> <NavLink to="/faculty">Faculty</NavLink> </li>
              <li> <NavLink to="/departments">Departments</NavLink> </li>
              <li> <NavLink to="/Facilities">Facilities</NavLink> </li>
              <li> <NavLink to="/students-corner">Students Corner</NavLink> </li>
              <li> <NavLink to="/admission-center">Admission Center</NavLink> </li>
              <li> <NavLink to="/news-and-developments">News & Development</NavLink> </li>
              <li> <NavLink to="/tmv-gallery">TMV Gallery</NavLink> </li>
              <li> <NavLink to="/contact-us">Contact Us</NavLink> </li>
            </ul>
          </div>


        </div>
      </header>
    </>
  )
}

export default Header
