import React from 'react'
import tmvUnderline from '../Images/tmvUnderline.png'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

function PharmacyDept() {
  return (
    <>
    <Header/>
    <div class="pageHeader">
        <h3>Pharmacy Department</h3>
        <img src={tmvUnderline} alt="" />
      </div>
      <div className="contactContainer">
        <div className="contactBox1">
          <img src={tmvUnderline} alt="" />
          <h2>B Pharmacy</h2>
          <p>Bachelor of Pharmacy</p>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum cum expedita, eaque totam rem aspernatur illum aliquam aperiam neque veritatis quidem ea dolore esse unde.</p>
        </div>
        <div className="contactBox1">
          <img src={tmvUnderline} alt="" />
          <h2>M Pharmacy</h2>
          <p>Master of Pharmacy</p>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum cum expedita, eaque totam rem aspernatur illum aliquam aperiam neque veritatis quidem ea dolore esse unde.</p>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default PharmacyDept
