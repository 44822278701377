import React from 'react'
import './TMVGallery.css'
import tmvUnderline from '../../Images/tmvUnderline.png'
import img1 from '../../Images/tmv5.jpg'
import img2 from '../../Images/tmvBg2024.jpeg'
import img3 from '../../Images/event1.jpg'
import img4 from '../../Images/tmv4.jpg'
import img5 from '../../Images/tmv2.jpg'
import img6 from '../../Images/tmvBanner.jpeg'
import img7 from '../../Images/tmvBg2024-2.jpeg'
import img8 from '../../Images/tmvBg2024.jpeg'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'


function TMVGallery() {
  return (
    <>
    <Header/>
    <div class="pageHeader">
        <h3>TMV Gallery</h3>
        <img src={tmvUnderline} alt="" />
    </div>
    <div className="galleryContainer">
        <div className="galleryBox" id="gbox-1">
          <img src={img1} alt="" />
        </div>
        <div className="galleryBox" id="gbox-2">
          <img src={img2} alt="" />
        </div>
        <div className="galleryBox" id="gbox-3">
          <img src={img3} alt="" />
        </div>
        <div className="galleryBox" id="gbox-4">
          <img src={img4} alt="" />
        </div>
        <div className="galleryBox" id="gbox-5">
          <img src={img5} alt="" />
        </div>
        <div className="galleryBox" id="gbox-6">
          <img src={img6} alt="" />
        </div>
        <div className="galleryBox" id="gbox-7">
          <img src={img7} alt="" />
        </div>
        <div className="galleryBox" id="gbox-8">
          <img src={img8} alt="" />
        </div>
        
      </div>
      <Footer/>
    </>
  )
}

export default TMVGallery
