import React from 'react'
import tmvUnderline from '../../Images/tmvUnderline.png'
import tmvBlackUnderline from '../../Images/blackUnderline.png'
import './NewsAndDevelopments.css'
import News from '../../Images/tmv1.jpg'
import second from '../../Images/tmv2.jpg'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

function NewsAndDevelopments() {
  return (
    <>
    <Header/>
    <div class="pageHeader">
        <h3>News And Developmenmts</h3>
        <img src={tmvUnderline} alt="" />
    </div>
    <div className="newsContainer">
        <div className="newsImg">
          <img src={News} alt="" />
        </div>
        <div className="info">
          <h3>Latest</h3>
          <h1>News And Development</h1>
          <img src={tmvBlackUnderline} alt="" />
          <p>
            TMV Kharghar celebrates academic achievements with new research initiatives and fosters student growth through innovative programs and community engagement.
          </p>
          <div className="points">
            <div className="first">
              <img src={second} alt="" />
              <div>
                <h1>Research Advancements</h1>
                <p>Pioneering Studies in Sustainable Technology and Environmental Conservation.
                </p>
              </div>
            </div>
            <div className="first">
              <img src={second} alt="" />
              <div>
                <h1>Student Outreach</h1>
                <p>Empowering Communities through Volunteer Initiatives and Social Impact Projects.
                </p>
              </div>
            </div>
            <div className="first">
              <img src={second} alt="" />
              <div>
                <h1>Faculty Excellence</h1>
                <p>Recognized Scholars Lead with Expertise and Mentorship in Diverse Fields.
                </p>
              </div>
            </div>
            <div className="first">
              <img src={second} alt="" />
              <div>
                <h1>Campus Innovations</h1>
                <p>Modern Facilities and Technology Integration for Enhanced Learning Experiences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default NewsAndDevelopments
