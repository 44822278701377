import React from 'react'
import tmvUnderline from '../../Images/tmvUnderline.png'
import './Facilities.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

function Facilities() {
  return (
    <>
    <Header/>
      <div class="pageHeader">
        <h3>Facilities</h3>
        <img src={tmvUnderline} alt="" />
      </div>

      <div className="facilities">
        <div className="gallery">
          <div className="box row-2" id='box1'><h2>Computer Lab</h2></div>
          <div className="box col-2" id='box2'><h2>Library</h2></div>
          <div className="box" id='box3'><h2>Cafereria</h2></div>
          <div className="box row-2" id='box4'><h2>Chemistry Lab</h2></div>
          <div className="box col-2" id='box5'><h2>Play Ground</h2></div>
          <div className="box" id='box6'><h2>Gym</h2></div>
          <div className="box col-2" id='box7'><h2>Big Classrooms</h2></div>
        </div>
      </div>
    <Footer/>
    </>
  )
}

export default Facilities
