import React from 'react'
import tmvUnderline from '../../Images/tmvUnderline.png'
import tmvUnderlineBlack from '../../Images/blackUnderline.png'
import './Faculty.css'
import fac1 from '../../Images/fac.jpg'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Link } from 'react-router-dom'



function Faculty() {
  return (
    <>
    <Header/>
    <div class="pageHeader">
        <h3>Faculty</h3>
        <img src={tmvUnderline} alt="" />
    </div>
    <div className="facultyContainer">
        <h1>Computer Science Department</h1>
        <img src={tmvUnderlineBlack} alt="" />
        <div className="faculties">
          <div className="faculty">
            <img src={fac1} alt="" />
            <div className='facultyInfoDiv'>
              <h1>Mrs. Poornima Jangle</h1>
              <h3>HOD</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </p>
              <Link to="/single-faculty-page-1">Know More</Link>
            </div>
          </div>
          <div className="faculty">
            <img src={fac1} alt="" />
            <div className='facultyInfoDiv'>
              <h1>Mrs. Deepmala Sharma</h1>
              <h3>Assistant Professor</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </p>
              <Link to="/single-faculty-page-2">Know More</Link>
            </div>
          </div>
          <div className="faculty">
            <img src={fac1} alt="" />
            <div className='facultyInfoDiv'>
              <h1>Mrs. Minal Mandwe</h1>
              <h3>Assistant Professor</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </p>
              <Link to="/single-faculty-page-3">Know More</Link>
            </div>
          </div>
        </div>

      </div>
    {/* <div className="facultyContainer">
        <h1>Law Department</h1>
        <img src={tmvUnderlineBlack} alt="" />
        <div className="faculties">
          <div className="faculty">
            <img src={fac1} alt="" />
            <div className='facultyInfoDiv'>
              <h1>Name</h1>
              <h3>HOD</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div className="faculty">
            <img src={fac1} alt="" />
            <div className='facultyInfoDiv'>
              <h1>Name</h1>
              <h3>Assistant Professor</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div className="faculty">
            <img src={fac1} alt="" />
            <div className='facultyInfoDiv'>
              <h1>Name</h1>
              <h3>Assistant Professor</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </p>
            </div>
          </div>
        </div>

      </div>
    <div className="facultyContainer">
        <h1>Pharmacy Department</h1>
        <img src={tmvUnderlineBlack} alt="" />
        <div className="faculties">
          <div className="faculty">
            <img src={fac1} alt="" />
            <div className='facultyInfoDiv'>
              <h1>Name</h1>
              <h3>HOD</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div className="faculty">
            <img src={fac1} alt="" />
            <div className='facultyInfoDiv'>
              <h1>Name</h1>
              <h3>Assistant Professor</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div className="faculty">
            <img src={fac1} alt="" />
            <div className='facultyInfoDiv'>
              <h1>Name</h1>
              <h3>Assistant Professor</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </p>
            </div>
          </div>
        </div>

      </div> */}
      <Footer/>
    </>
  )
}

export default Faculty
