import React, {useState, useEffect} from 'react';
import tmvUnderline from '../../Images/tmvUnderline.png'
import blackUnderline from '../../Images/blackUnderline.png'
import './AdmissionCenter.css'
import admissionImage from '../../Images/tmvBanner.jpeg'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

function AdmissionCenter() {
  const [formData, setFormData] = useState({
    name: '',
    qualification: '',
    course: '',
    phone: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Validation function
    const validateForm = () => {
      let errorsObj = {};
      if (!formData.name.trim()) {
        errorsObj.name = 'Name is required';
      } else if (!/^[a-zA-Z\s]*$/.test(formData.name.trim())) {
        errorsObj.name = 'Name cannot contain numbers';
      }
      if (!formData.qualification.trim()) {
        errorsObj.qualification = 'Qualification is required';
      }
      if (!formData.course.trim()) {
        errorsObj.course = 'Course is required';
      }
      if (!formData.phone.trim()) {
        errorsObj.phone = 'Phone number is required';
      } else if (!/^\d{10}$/.test(formData.phone.trim())) {
        errorsObj.phone = 'Invalid phone number';
      }
      if (!formData.email.trim()) {
        errorsObj.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
        errorsObj.email = 'Invalid email address';
      }
      setErrors(errorsObj);
    };

    validateForm();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name' && /\d/.test(value)) return; // Prevent entering numbers in name field
    if (name === 'phone' && /\D/.test(value)) return; // Prevent entering non-digits in phone field
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log(formData);
  };

  return (
    <>
      <Header />
      <div class="pageHeader">
        <h3>Admission Center</h3>
        <img src={tmvUnderline} alt="" />
      </div>
      <div className="addForm">
        <div className="addFormSection1">
          <img src={admissionImage} alt="" />
        </div>
        <div className="addFormSection2">
          <h1>Send Admission Enquiry</h1>
          <img src={blackUnderline} alt="" />
          <p>Fill the form and get a Callback from our admission supervisor</p>
          <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        placeholder="Enter Your Name"
        value={formData.name}
        onChange={handleChange}
      />
      
      <input
      required
        type="text"
        name="qualification"
        placeholder="Enter Your Current Qualification"
        value={formData.qualification}
        onChange={handleChange}
      />
      
      <input
      required
        type="text"
        name="course"
        placeholder="Enter Course You Want"
        value={formData.course}
        onChange={handleChange}
      />
      
      <input
      required
        type="tel"
        name="phone"
        placeholder="Your Phone"
        value={formData.phone}
        onChange={handleChange}
      />
      
      <input
      required
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
      />
      
      <textarea
      required
        name="message"
        placeholder="Message"
        className="textareaCSS"
        rows={10}
        value={formData.message}
        onChange={handleChange}
      />
      <button type="submit">Submit</button>
    </form>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AdmissionCenter
