import React from 'react'
import tmvUnderline from '../../Images/tmvUnderline.png'
import './ContactUs.css'
import contactImg from '../../Images/tmvBg2024.jpeg'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

function ContactUs() {
  return (
    <>
    <Header/>
      <div class="pageHeader">
        <h3>Contact Us</h3>
        <img src={tmvUnderline} alt="" />
      </div>
      <div className="contactContainer">

        <div className="contactBox2">
          <img src={tmvUnderline} alt="" />
          <h2>Our Contact</h2>
          <ul>
            <li>+91 99999 88888</li>
            <li>+91 99999 88888</li>
          </ul>
        </div>
        <div className="contactBox1">
          <img src={tmvUnderline} alt="" />
          <h2>Our Location</h2>
          <p>TMV - Kharghar Plot No - 3, Sector - 14, Near Raghunath-Vihar CHS,Kharghar -410210</p>
        </div>
        <div className="contactBox3">
          <img src={tmvUnderline} alt="" />
          <h2>Mail Us</h2>
          <p>info@tmvkharghar.in</p>
          <p>admission@tmvkharghar.in</p>
        </div>
      </div>
      <div className="contactForm">
        <div className="formSection1">
          <img src={contactImg} alt="" />
        </div>
        <div className="formSection2">
          <h1>Get In Touch</h1>
          <img src={tmvUnderline} alt="" />
          <p>Send us a message</p>
          <input type="text" placeholder='Your Name' />
          <input type="phone" placeholder='Your Phone' />
          <input type="email" placeholder='Email' />
          <textarea type="text" placeholder='Message' />
          <button>Contact Us</button>
        </div>
      </div>
      <div className="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.4418128647376!2d73.07142287520453!3d19.044302882153765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c210444a52e3%3A0x353c5655e695052d!2z4KSf4KS_4KSz4KSVIOCkruCkueCkvuCksOCkvuCkt-CljeCkn-CljeCksCDgpLXgpL_gpKbgpY3gpK_gpL7gpKrgpYDgpKA!5e0!3m2!1smr!2sin!4v1714717478958!5m2!1smr!2sin" allowfullscreen="" title='tmvKharghar' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <Footer/>
    </>
  )
}

export default ContactUs
