
import React from 'react'
import './Home.css'
import aboutImg from '../../Images/tmvBg2024-r.jpg'
import tmvUnderline from '../../Images/tmvUnderline.png'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Link } from 'react-router-dom'


  
function Home() {
  return (
    <>
    <Header/>
    <div class="main">
        <h3>Welcome To</h3>
        <img src={tmvUnderline} alt="" />
        <h1>TMV Kharghar</h1>
        <p>The origin of the Vidyapeeth dates back to pre-independence period – 1921 when immediately after the demise of Lokmanya Bal Gangadhar Tilak, his follower Mahatma Gandhi mooted the idea of establishing a university in his memory.</p>
        <h2>+91-98921-10892</h2>
    </div>
    <div class="about">
        <div class="about1">
            <img src={aboutImg} alt=""/>
        </div>
        <div class="about2">
            <h2>About Us</h2>
            <p>The origin of the Vidyapeeth dates back to the pre-independence period - 1921 when immediately after the demise of Lokmanya Bal Gangadhar Tilak, his follower Mahatma Gandhi mooted the idea of establishing a university in his memory. As we all are aware one of the postulates of the four fold formula conceived by Lokmanya Tilak was National Education, the other three being Swaraj, Swadeshi and Boycott of Foreign Goods. The delegates of the first Maharashtra Provincial Conference on 6th May 1921 under the Presidentship of Shrimat Shankaracharya of Karveer Peeth decided to have a National University in Maharashtra and thus the present day Tilak Maharashtra Vidyapeeth (TMV) was established.</p>
            <p>Since it's inception, TMV, the public funded university had world renowned scholar Shri Shankaracharya Vidyashankar Bharati alias Dr. Kurtakoti as its first Chancellor. </p>
            
            <Link to="http://localhost:3000/about-us"><button>Know More</button></Link>
        </div>
    </div>
    <div className="mapContainer">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.4418128647376!2d73.07142287520453!3d19.044302882153765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c210444a52e3%3A0x353c5655e695052d!2z4KSf4KS_4KSz4KSVIOCkruCkueCkvuCksOCkvuCkt-CljeCkn-CljeCksCDgpLXgpL_gpKbgpY3gpK_gpL7gpKrgpYDgpKA!5e0!3m2!1smr!2sin!4v1714717478958!5m2!1smr!2sin"  allowfullscreen="" title='tmvKharghar' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

    <Footer/>
    </>
  )
}

export default Home
