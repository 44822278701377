import React from 'react'
import './StudentsCorner.css'
import tmvUnderline from '../../Images/tmvUnderline.png'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Link } from 'react-router-dom'

function StudentsCorner() {
  return (
    <>
    <Header/>
      <div class="pageHeader">
        <h3>Students Corner</h3>
        <img src={tmvUnderline} alt="" />
      </div>
      <div className="scCornerContainer">
        <div className="scCorner">
          <div className="scBox row-2" id='scBox1'>
            <Link to="https://www.tmv.edu.in/frmExamCorner1.aspx?val=All" target='himali'><h2>Exam Timetable</h2></Link>
            </div>
          <div className="scBox col-2" id='scBox2'>
          <Link to="https://www.tmv.edu.in/frmExamCorner1.aspx?val=All#collapse12" target='himali'><h2>Results</h2></Link>
            </div>
          <div className="scBox" id='scBox3'>
          <Link to="https://www.tmv.edu.in/frmExamCorner1.aspx?val=All#collapse12" target='himali'><h2>Exam Forms</h2></Link>
            </div>
          <div className="scBox" id='scBox4'>
          <Link to="https://www.tmv.edu.in/DeptLibrary/frmeresourses.aspx" target='himali'><h2>Old Question Papers</h2></Link>
            </div>
         
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default StudentsCorner
